import { Box } from "@mui/material";
import React from "react";

const Fallback = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        WebkitAnimation: "fadeinout 1s linear forwards",
        animation: "fadeinout 1s linear forwards",
      }}
    >
      <Box
        component="img"
        src="/assets/logo.png"
        sx={{
          height: { xs: "50px", lg: "100px" },
          objectFit: "cover",
        }}
      />
    </Box>
  );
};

export default Fallback;
