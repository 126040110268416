import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

function OTPInputField({ length, onComplete, queryOtp }) {
  const initializeOtp = useCallback(
    () =>
      queryOtp
        ? queryOtp
            .split("")
            .concat(new Array(length - queryOtp.length).fill(""))
        : new Array(length).fill(""),
    [queryOtp, length]
  );

  const [otp, setOtp] = useState(initializeOtp);

  useEffect(() => {
    setOtp(initializeOtp());
  }, [initializeOtp]);

  const handleChange = (element, index) => {
    const newOtp = [...otp];
    newOtp[index] = element.value.replace(/[^0-9]/g, "");
    setOtp(newOtp);
    if (element.value && index < length - 1) {
      document.getElementById(`otp-field-${index + 1}`).focus();
    }
    if (newOtp.every((num) => num.length === 1)) {
      onComplete(newOtp.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (!otp[index] && index > 0) {
        e.preventDefault();
        const prevSibling = document.getElementById(`otp-field-${index - 1}`);
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        prevSibling.focus();
      }
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {otp.map((value, index) => (
        <TextField
          key={index}
          id={`otp-field-${index}`}
          type="text"
          inputProps={{
            maxLength: 1,
            style: { textAlign: "center", width: "10px", height: "5px" },
          }}
          value={value}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={(e) => e.target.select()}
        />
      ))}
    </Box>
  );
}

export default OTPInputField;
