// GlobalStyles.js
import { css } from "@emotion/react";

export const globalStyles = css`
  /* Customize the scrollbar width, color, and other properties */
  /* Webkit (Safari/Chrome) Scrollbar */
  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Customize the scrollbar thumb color */
    border-radius: 0.8rem; /* Customize the scrollbar thumb border radius */
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Firefox Scrollbar */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* Customize the scrollbar thumb color */
  }

  /* Internet Explorer Scrollbar (optional, for older versions) */
  html {
    scrollbar-base-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Customize the scrollbar base color */
  }

  /* Auto-hide the scrollbar when not in use */
  /* This will hide the scrollbar when not actively scrolling */
  /* You can adjust the visibility and transition properties as needed */
  ::-webkit-scrollbar-thumb:horizontal {
    transition: background-color 0.2s;
  }

  ::-webkit-scrollbar-thumb:hover:horizontal {
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Change the thumb color when hovered over */
  }

  ::-webkit-scrollbar-thumb:vertical {
    transition: background-color 0.2s;
  }

  ::-webkit-scrollbar-thumb:hover:vertical {
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Change the thumb color when hovered over */
  }

  /* Hide scrollbar track and thumb when not in use */
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  /* You can add more custom styles as needed */
`;
