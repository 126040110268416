import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../recoil/snackbar";
import { useMutation } from "react-query";
import axios from "axios";
import { Eye, EyeSlash } from "phosphor-react";

const ChangePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const openAlert = useSetRecoilState(withAlert);
  const baseURL = process.env.REACT_APP_SERVICE_BASE_URL;
  const otpToken = localStorage.getItem("otpToken");
  const email = location?.state?.email;

  const [newData, setNewData] = useState({
    email: email,
    password: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleOnChange = (e) => {
    setNewData({ ...newData, [e.target.name]: e.target.value });
  };

  const { isLoading: isChangePasswordLoading, mutate: changePasswordMutate } =
    useMutation(
      async (data) => {
        const response = await axios.patch(
          `${baseURL}/api/authentication/password-update`,
          data,
          {
            headers: {
              Authorization: `Bearer ${otpToken}`,
            },
          }
        );
        return response;
      },
      {
        onSuccess: (res) => {
          if (res.status === 200) {
            openAlert({
              status: 200,
              detail:
                "Password changed successfully. Please log in with the new password.",
            });
            navigate("/login");
          }
        },
        onError: (error) => {
          openAlert({
            status: 500,
            detail: error.response.data.message
              ? error.response.data.message
              : "There was an error when trying to change your password.",
          });
        },
      }
    );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newData.password !== newData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    } else if (newData.password === "" || newData.confirmPassword === "") {
      setPasswordError("Password must be filled!");
    } else {
      setPasswordError("");
      changePasswordMutate({
        email: newData.email,
        password: newData.password,
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {/* ----------------------LEFT SECTION START---------------------- */}

      <Box
        sx={{
          flexGrow: 0,
          display: { lg: "flex", xs: "none" },
          backgroundColor: "primary.light",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "500px",
        }}
      >
        <Box sx={{ padding: "50px" }}>
          <Box
            component="img"
            src="/assets/logo.png"
            sx={{
              height: "50px",
              objectFit: "cover",
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: "primary.main",
              marginTop: "10px",
              textTransform: "uppercase",
            }}
          >
            Reach Your Thousands of Customers Within Minutes
          </Typography>
          <Typography
            sx={{
              marginTop: "10px",
              color: "secondary.light",
            }}
          >
            Extend Your Market and boost your revenue with BOOM Viber Service
          </Typography>
        </Box>
        <Box
          component="img"
          src="/assets/login-vector.png"
          sx={{
            marginTop: "30px",
            width: "350px",
            objectFit: "cover",
          }}
        />
      </Box>

      {/* -----------------RIGHT SECTION START------------------------ */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          height: "100vh",
          position: "relative",
          overflowX: "hidden",
        }}
      >
        <Box sx={{ zIndex: 2, width: "100%" }}>
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: "40px",
            }}
          >
            <Box
              component="img"
              src="/assets/logo.png"
              sx={{
                display: { lg: "none" },
                height: "50px",
                objectFit: "cover",
              }}
            />

            <Box
              component="form"
              sx={{ width: "100%" }}
              onSubmit={handleSubmit}
            >
              <Typography
                variant="h6"
                sx={{ color: "primary.main", fontWeight: 700 }}
              >
                Change your password
              </Typography>
              <Typography sx={{ color: "secondary.light", mt: "0.5rem" }}>
                Enter new password for your BOOM Viber Portal account.
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  marginTop: "20px",
                  marginBottom: "8px",
                  color: "secondary.light",
                }}
              >
                Email
              </Typography>
              <TextField
                size="small"
                fullWidth
                name="email"
                value={newData?.email}
                disabled
                onChange={handleOnChange}
              />
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  marginTop: "20px",
                  marginBottom: "8px",
                  color: "secondary.light",
                }}
              >
                Password
              </Typography>
              <TextField
                size="small"
                type={showPassword ? "text" : "password"}
                fullWidth
                name="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Eye size={20} />
                        ) : (
                          <EyeSlash size={20} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: passwordError ? "secondary.main" : "primary",
                    },
                    "& fieldset": {
                      borderColor: passwordError ? "secondary.main" : "primary",
                    },
                  },
                  input: {
                    color: passwordError ? "secondary.main" : "primary",
                  },
                }}
                onChange={handleOnChange}
              />
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  marginTop: "20px",
                  marginBottom: "8px",
                  color: "secondary.light",
                }}
              >
                Confirm Password
              </Typography>
              <TextField
                size="small"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                name="confirmPassword"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Eye size={20} />
                        ) : (
                          <EyeSlash size={20} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: passwordError ? "secondary.main" : "primary",
                    },
                    "& fieldset": {
                      borderColor: passwordError ? "secondary.main" : "primary",
                    },
                  },
                  input: {
                    color: passwordError ? "secondary.main" : "primary",
                  },
                }}
                onChange={handleOnChange}
              />
              {passwordError && (
                <Typography
                  sx={{
                    color: "secondary.main",
                    fontSize: "0.9rem",
                    mt: "0.5rem",
                  }}
                >
                  {passwordError}
                </Typography>
              )}
              <Box height="20px" />
              <Stack direction="row" gap={2}>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/resetpassword")}
                  disableElevation
                  sx={{
                    width: "50%",
                    fontWeight: 700,
                  }}
                >
                  back
                </Button>
                <LoadingButton
                  variant="contained"
                  disableElevation
                  loading={isChangePasswordLoading}
                  type="submit"
                  sx={{
                    width: "50%",
                    fontWeight: 700,
                  }}
                >
                  Change password
                </LoadingButton>
              </Stack>
            </Box>
          </Container>
        </Box>

        {/*-----------------BACKGROUND IMGAES---------------------------------------*/}
        <Box
          component="img"
          src="/assets/plane.svg"
          sx={{
            display: { xs: "none", lg: "block" },
            height: "100vh",
            objectFit: "cover",
            position: "absolute",
            right: "-50px",
            overflow: "hidden",
            zIndex: 1,
          }}
        />
        <Box
          component="img"
          src="/assets/login-vector.png"
          sx={{
            display: { lg: "none" },
            height: "30vh",
            opacity: 0.1,
            objectFit: "cover",
            position: "absolute",
            overflow: "hidden",
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default ChangePassword;
