import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    // use for mui components like Button and input
    primary: {
      main: "#7360F2",
      light: "#e8e3ff",
      dark: "#443c8a",
    },
    // use for typo color and line
    secondary: {
      main: "#F26073",
      light: "#333",
    },
  },
});
