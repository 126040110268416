import React, { useState } from "react";
import {
  Box,
  Chip,
  Divider,
  Typography,
  TextField,
  Container,
  Link,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../recoil/snackbar";
import axios from "axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const openAlert = useSetRecoilState(withAlert);
  const [data, setData] = useState({
    company_name: null,
    company_address: null,
    company_phone_number: null,
    company_email: null,
    user_name: null,
    user_email: null,
    user_password: null,
    user_confirm_password: null,
  });

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleRegister = () => {
    if (data.user_password !== data.user_confirm_password) {
      openAlert({
        status: 400,
        detail: "Passwords do not match.",
      });
    } else {
      mutate(data);
    }
  };

  const { isLoading, mutate } = useMutation(
    (data) => {
      return axios.post(
        `${process.env.REACT_APP_SERVICE_BASE_URL}/api/company/register`,
        data,
        {
          validateStatus: function (status) {
            return status <= 500;
          },
        }
      );
    },
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          navigate("/login");
        }
        openAlert({
          status: res.status,
          detail:
            res.data?.message && typeof res.data?.message === "string"
              ? res.data.message
              : res.data.message[0],
        });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(data);
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {/* ----------------------LEFT SECTION START---------------------- */}

      <Box
        sx={{
          flexGrow: 0,
          display: { lg: "flex", xs: "none" },
          backgroundColor: "primary.light",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "500px",
        }}
      >
        <Box sx={{ padding: "50px" }}>
          <Box
            component="img"
            src="/assets/logo.png"
            sx={{
              marginTop: "20px",
              height: "50px",
              objectFit: "cover",
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: "primary.main",
              marginTop: "10px",
              textTransform: "uppercase",
            }}
          >
            Reach Your Thousands of Customers Within Minutes
          </Typography>
          <Typography
            sx={{
              marginTop: "10px",
              color: "secondary.light",
            }}
          >
            Extend Your Market and boost your revenue with BOOM Viber Service
          </Typography>
        </Box>
        <Box
          component="img"
          src="/assets/register-vector.png"
          sx={{
            width: "350px",
            objectFit: "cover",
          }}
        />
      </Box>

      {/* -----------------RIGHT SECTION START------------------------ */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: { xs: "start", lg: "center" },
          flexGrow: 1,
          height: { lg: "100vh" },
          position: "relative",
          overflowX: "hidden",
        }}
      >
        <Typography
          sx={{
            display: { xs: "none", lg: "block" },
            fontSize: "0.8rem",
            padding: "2rem",
            position: "absolute",
            right: "0px",
            top: "0px",
            userSelect: "none",
            zIndex: 3,
          }}
        >
          Already have an account?
          <Button
            size="small"
            component="span"
            onClick={() => navigate("/login")}
          >
            Login.
          </Button>
        </Typography>

        <Box sx={{ zIndex: 2, width: "100%" }}>
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: "40px",
            }}
          >
            <Box
              component="img"
              src="/assets/logo.png"
              sx={{
                marginTop: "20px",
                display: { lg: "none" },
                height: "50px",
                objectFit: "cover",
              }}
            />

            <Box
              component="form"
              sx={{ width: "100%" }}
              onSubmit={handleSubmit}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "primary.main",
                  fontWeight: 700,
                }}
              >
                Register to BOOM Viber
              </Typography>
              <Divider sx={{ marginTop: "20px" }}>
                <Chip label="Company" />
              </Divider>

              <TextField
                onChange={handleOnChange}
                label="Company Name*"
                size="small"
                name="company_name"
                fullWidth
                sx={{ marginY: "1rem" }}
              />

              <TextField
                onChange={handleOnChange}
                label="Company Address*"
                size="small"
                fullWidth
                name="company_address"
                sx={{ marginBottom: "1rem" }}
              />

              <TextField
                onChange={handleOnChange}
                label="Company Phone*"
                size="small"
                fullWidth
                name="company_phone_number"
                sx={{ marginBottom: "1rem" }}
              />

              <TextField
                onChange={handleOnChange}
                label="Company Email*"
                size="small"
                fullWidth
                name="company_email"
              />

              <Divider sx={{ my: 2 }}>
                <Chip label="Account" />
              </Divider>

              <TextField
                onChange={handleOnChange}
                label="Name*"
                size="small"
                name="user_name"
                fullWidth
                sx={{
                  marginBottom: "1rem",
                }}
              />

              <TextField
                onChange={handleOnChange}
                label="Email*"
                size="small"
                fullWidth
                name="user_email"
                sx={{ marginBottom: "1rem" }}
              />

              <TextField
                onChange={handleOnChange}
                label="Password*"
                size="small"
                type="password"
                fullWidth
                name="user_password"
                sx={{ marginBottom: "1rem" }}
              />

              <TextField
                onChange={handleOnChange}
                label="Confirm Password*"
                size="small"
                type="password"
                fullWidth
                name="user_confirm_password"
              />

              <Typography sx={{ fontSize: "0.8rem", my: 2 }}>
                By registering you are agreeing to our
                <Link
                  href={`${process.env.REACT_APP_LANDING_SITE_URL}/term_and_conditions#Terms`}
                >
                  &nbsp;Terms of Service.
                </Link>
              </Typography>

              <LoadingButton
                variant="contained"
                disableElevation
                loading={isLoading}
                onClick={handleRegister}
                sx={{
                  width: "50%",
                  fontWeight: 700,
                }}
              >
                Register
              </LoadingButton>
            </Box>
            <Typography
              sx={{
                display: { xs: "block", lg: "none" },
                fontSize: "0.8rem",
                width: "100%",
                marginBottom: "1rem",
                textAlign: "center",
                userSelect: "none",
                zIndex: 3,
              }}
            >
              Already have an account?
              <Link href="login" sx={{ fontWeight: 700, cursor: "pointer" }}>
                Login.
              </Link>
            </Typography>
          </Container>
        </Box>
        <Box
          component="img"
          src="/assets/plane.svg"
          sx={{
            display: { xs: "none", lg: "block" },
            height: "100vh",
            objectFit: "cover",
            position: "absolute",
            right: "-50px",
            overflow: "hidden",
            zIndex: 1,
          }}
        />
        <Box
          component="img"
          src="/assets/register-vector.png"
          sx={{
            display: { lg: "none" },
            height: "30vh",
            opacity: 0.1,
            objectFit: "cover",
            position: "absolute",
            top: "30vh",
            overflow: "hidden",
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default Register;
